.custom-header a.navbar-brand img {
  width: 160px;
}
.custom-header .navbar-brand {
  margin: 0;
  padding: 0;
}
.custom-header nav.navbar.navbar-expand-lg.bg-body-tertiary {
  background: #ffffff !important;
}
.banner-p-1 {
  font-size: 50px;
  font-weight: bold;
  line-height: 65px;
  margin: 0 0 15px 0;
}
.banner-p-2 {
  font-size: 16px;
  margin: 0 0 30px 0;
  font-weight: 400;
  color: black;
}
.mar-15 {
  margin: 0 0 15px 0;
}
.pass-inputs {
  height: 60px;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.subscribe {
  background: #4050fb;
  border: none;
  height: 60px;
  line-height: 60px;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  padding: 0 30px;
  border-radius: 4px;
  min-width: 200px;
  text-align: center;
  justify-content: center;
  border: 1px solid #343eac;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.subscribe img {
  width: 25px;
  margin: 0 0 0 10px;
  position: relative;
  top: 1px;
}
.about-img {
  width: 100%;
  max-width: 510px;
  margin: auto;
  display: block;
}
.banner-section {
  min-height: calc(100vh - 71px);
  display: flex;
  align-items: center;
  background-color: white;
  padding: 60px 0;
}
.left-banner {
  padding: 0 50px 0 0;
}
.pass-inputs:hover,
.pass-inputs:focus {
  outline: 2px solid #343eac;
}
.banner-p-1 span {
  color: #4050fb;
}
.align-self {
  align-items: center;
}

@media (max-width: 1024px) {
  .left-banner {
    padding: 0;
  }
  .banner-p-1 {
    font-size: 40px;
    line-height: 47px;
  }
}

@media (max-width: 991px) {
  .left-banner {
    margin: 0 0 30px 0;
  }
  button.navbar-toggler {
    display: none;
  }
}

@media (max-width: 767px) {
  .banner-p-1 {
    font-size: 34px;
    line-height: 40px;
  }
  .banner-section {
    padding: 40px 0;
  }
  .subscribe {
    width: 100%;
    font-size: 16px;
    height: 50px;
  }
  .banner-p-2 {
    font-size: 14px;
    margin: 0 0 15px 0;
  }
  .pass-inputs {
    height: 50px;
    font-size: 14px;
  }
  .subscribe img {
    width: 18px;
  }
  .left-banner {
    margin: 0 0 15px 0;
  }
}
